import React from "react"
import "../styles/menu.css"

export default function Menu() {
    return (
        <header>
          <ul class="inline-block">
            <li><a href="/about" class="mnBtn">About</a></li>
            <li><a href="/" class="mnBtn">Blog</a></li>
            <li><a href="/projects" class="mnBtn">Projects</a></li>
            <li><a href="/books" class="mnBtn">Books</a></li>
          </ul>
        </header>
    )
}
